<template>
  <div class="index_container agreement">
    <ItemScroll>
      <div class="agreement_main">
        <p style="text-align: center; font-size: 16px; text-wrap: wrap">
          <strong>隐私协议</strong>
        </p>
        <p style="font-size: 16px; text-wrap: wrap">
          <strong>导言</strong>
        </p>

        <p style="font-size: 14px; text-wrap: wrap">
          上海医飞科技有限公司《以下合称为”
          <strong>医飞</strong>
          ”。“<strong>我们</strong>“或“<strong>我们的</strong>“)
          充分尊重您的隐私权。我们特此制定本《隐私政策》(以下简称为“<strong>本政策”</strong>)
          以便您了解我们如何搜集、使用、披露、保护、存储及传输您的个人数据。
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          请您仔细阅读本政策声明；如您有任何疑问，请联系我们。
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          医飞严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: 14px; text-wrap: wrap"
        >
          <li>
            我们竭尽全力通过合理有效的信息安全技术及管理流程，防止您的信息泄露、揭毁、丢失。
          </li>
          <li>
            我们为您提供便利的信息管理选项，以便您做出合适的选择，管理您的个人信息。
          </li>
          <li>
            我们严格遵照法律法规，保护您的通信秘密，为您提供安全的通信服务。
          </li>
          <li>为了向您和其他用户提供更好的服务，我们仅收集必要的信息。</li>
        </ol>

        <p
          style="
            font-size: 16px;
            font-weight: bold;

            text-wrap: wrap;
          "
        >
          <strong><span>收集的信息</span></strong>
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          您在使用我们服务时主动提供的信息
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: 14px; text-wrap: wrap"
        >
          <li>
            您在注册账户时填写的信息：手机号码、邮箱、姓名、性别、生日信息、医院信息、科室信息、从业信息等
          </li>
          <li>您在注册帐户时上传的信息: 个人头像、演讲资料等</li>
          <li>
            您在认证时所需要上传的信息：身份证正反面、从业证书、医师资格证
          </li>
          <li>
            参与会议、活动时，主办方要求填写的其它附加信息：真实姓名、地址等信息
          </li>
        </ol>

        <p style="font-size: 16px; font-weight: bold; text-wrap: wrap">
          <strong><span>在您使用服务时获取的信息</span></strong>
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          日志信息，当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: 14px; text-wrap: wrap"
        >
          <li>设备信息：设备型号、操作系统版本唯一设备标识符等信息。</li>
          <li>软件信息：软件的版本号、浏览器类型等</li>
          <li>IP地址、设备MAC地址，唯一设备识别码</li>
          <li>服务日志信息：查看的信息、服务故障信息、引荐网址等信息。</li>
        </ol>
        <p style="font-size: 16px; font-weight: bold; text-wrap: wrap">
          <strong><span>账号注销、停用</span></strong>
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          如果您需要注销或停用已经注册的账号信息请拔打客服电话:028-63857951（工作日9:30-18:00），我们将在七个工作日之内反馈给您处理结果。
        </p>
        <p style="font-size: 16px; font-weight: bold; text-wrap: wrap">
          <strong><span>信息更正删除</span></strong>
        </p>
        <p style="font-size: 14px; text-wrap: wrap">
          在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求:
        </p>
        <ol
          start="1"
          type="1"
          style="margin-bottom: 0; font-size: 14px; text-wrap: wrap"
        >
          <li>如果我们处理个人信息的行为违反法律法规；</li>
          <li>如果我们收集、使用您的个人信息，却未征得您的明确同意；</li>
          <li>如果我们处理个人信息的行为严重违反了与您的约建；</li>
          <li>
            如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；
          </li>
          <li>如果我们停止提供产品或者服务，或者保存期限已届满。</li>
        </ol>
        <p style="font-size: 14px; text-wrap: wrap">
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
        </p>

        <p><br /></p></div
    ></ItemScroll>
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "userAgreement",
  components: {
    ItemScroll,
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
  padding: 4vw;
}
.agreement_main {
  height: 100%;
}
.agreement ol li {
  margin-left: 40px;
}
.agreement p {
  margin: 1rem 0;
}
</style>
